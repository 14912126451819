import Vue from 'vue'
import vuetify from './plugins/vuetify'
import router from './router/'
import store from './store/'
import './registerServiceWorker'

import './theme/default.sass'
import App from './App.vue'
import '@mdi/font/css/materialdesignicons.css'
import axios from 'axios'

import MetaInfo from 'vue-meta-info'
Vue.use(MetaInfo)

// remove expired token
if (localStorage.getItem('access_token') && localStorage.getItem('expired')) {
  if (new Date().getTime() > localStorage.getItem('expired') * 1) {
    localStorage.removeItem('access_token')
    localStorage.removeItem('expired')
  }
}

axios.defaults.validateStatus = function(status) {
  if (status === 401 && router.currentRoute.name !== 'login') {
    localStorage.removeItem('access_token')
    localStorage.removeItem('expired')
    router.push('/auth/login')
  } else return true
}
axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest'
//axios.defaults.withCredentials = true

Vue.config.productionTip = true
Vue.prototype.$axios = axios

const app = new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App)
})

app.$mount('#app')
