import Vue from 'vue'

import Vuetify from 'vuetify/lib'
Vue.use(Vuetify)

import en from '@/locale/en'
import de from '@/locale/de'
import ru from '@/locale/ru'

export default new Vuetify({
  lang: {
    locales: { en, de, ru },
    current: localStorage.getItem('app_locale')
      ? localStorage.getItem('app_locale')
      : 'en'
  },
  theme: {
    options: {
      customProperties: true
    },
    themes: {
      light: {
        primary: '#ee44aa',
        secondary: '#424242',
        accent: '#82B1FF',
        error: '#FF5252',
        info: '#2196F3',
        success: '#4CAF50',
        warning: '#FFC107'
      }
    }
  }
})
