import {
  LayoutAuth,
  LayoutDefault,
  RouteWrapper
} from '@/components/layouts'

export const publicRoute = [
  {
    path: '*',
    component: () => import('@/views/error/NotFound.vue')
  },
  {
    path: '/auth',
    component: LayoutAuth,
    meta: {
      title: 'login.login'
    },
    redirect: '/auth/login',
    hidden: true,
    children: [
      {
        path: 'login',
        name: 'login',
        meta: {
          title: 'login.login',
          auth: false
        },
        component: () => import('@/views/auth/Login.vue')
      }
      /*{
        path: 'register',
        name: 'register',
        meta: {
          title: 'Register',
          auth: false
        },
        component: () => import('@/views/auth/Register.vue')
      }*/
    ]
  },

  {
    path: '/404',
    name: '404',
    meta: {
      title: 'Not Found',
      auth: false
    },
    component: () => import('@/views/error/NotFound.vue')
  },

  {
    path: '/500',
    name: '500',
    meta: {
      title: 'Server Error',
      auth: false
    },
    component: () => import('@/views/error/Error.vue')
  }
]

export const protectedRoute = [
  {
    path: '/',
    component: LayoutDefault,
    meta: {
      title: 'Home',
      group: 'apps',
      icon: '',
      auth: true
    },
    redirect: '/dashboard',
    children: [
      {
        path: '/dashboard',
        name: 'Dashboard',
        meta: {
          title: 'menu.dashboard',
          group: 'apps',
          icon: 'mdi-view-dashboard',
          auth: true
        },
        component: () => import('@/views/Dashboard')
      },
      {
        path: '/calls',
        meta: {
          title: 'call.many',
          group: 'apps',
          icon: 'mdi-phone-forward',
          auth: true,
          scope: 'view_calls'
        },
        name: 'Calls',
        component: () => import('@/views/calls/Calls')
      },
      {
        path: '/tickets',
        meta: {
          title: 'ticket.many',
          group: 'apps',
          icon: 'mdi-ticket-confirmation',
          auth: true,
          scope: 'view_tickets'
        },
        name: 'Tickets',
        component: () => import('@/views/ticket/Tickets')
      },
      {
        path: '/tickets/:id',
        props: true,
        meta: {
          hiddenInMenu: true,
          title: 'ticket.one',
          group: 'apps',
          auth: true,
          scope: 'view_tickets'
        },
        name: 'Ticket',
        component: () => import('@/views/ticket/Ticket')
      },
      {
        path: '/datev',
        component: RouteWrapper,
        meta: {
          title: 'different.datev',
          icon: 'list_alt',
          group: 'apps',
          scope: 'view_datev'
        },
        children: [
          {
            path: '/datev/export',
            name: 'DatevExport',
            meta: {
              title: 'different.export',
              auth: true,
              scope: 'view_datev'
            },
            component: () => import('@/views/datev/Export')
          },
          {
            path: '/datev/dealers',
            name: 'DatevDealers',
            meta: {
              title: 'menu.dealers',
              auth: true,
              scope: 'view_datev'
            },
            component: () => import('@/views/datev/Dealers')
          },
          {
            path: '/datev/exchange',
            name: 'DatevExchange',
            meta: {
              title: 'menu.exchange',
              auth: true,
              scope: 'view_datev'
            },
            component: () => import('@/views/datev/Exchange')
          },
          {
            path: '/datev/currency',
            name: 'DatevCurrency',
            meta: {
              title: 'different.currency',
              auth: true,
              scope: 'view_datev'
            },
            component: () => import('@/views/datev/Currency')
          },
          {
            path: '/datev/paypal',
            name: 'DatevPayPal',
            meta: {
              title: 'different.paypal',
              auth: true,
              scope: 'view_datev'
            },
            component: () => import('@/views/datev/PayPal')
          },
          {
            path: '/datev/amazon',
            name: 'DatevAmazon',
            meta: {
              title: 'different.amazon',
              auth: true,
              scope: 'view_datev'
            },
            component: () => import('@/views/datev/Amazon')
          },
          {
            path: '/datev/ebay',
            name: 'DatevEbay',
            meta: {
              title: 'different.ebay',
              auth: true,
              scope: 'view_datev'
            },
            component: () => import('@/views/datev/Ebay')
          }
        ]
      },
      {
        path: '/statistics',
        component: RouteWrapper,
        meta: {
          title: 'different.statistics',
          icon: 'equalizer',
          group: 'apps',
          auth: true,
          scope: 'view_stat'
        },
        children: [
          {
            path: '/statistics/turnover',
            name: 'StatisticTurnover',
            meta: {
              title: 'different.turnover',
              auth: true,
              scope: 'view_stat'
            },
            component: () => import('@/views/statistics/Turnover')
          },
          {
            path: '/statistics/valuation',
            name: 'StockValuation',
            meta: {
              title: 'different.stockValuation',
              auth: true,
              scope: 'viewStockValuation'
            },
            component: () => import('@/views/statistics/Valuation')
          }
        ]
      },
      {
        path: '/storage-performance',
        meta: {
          title: 'menu.storagePerformance',
          group: 'apps',
          icon: 'store',
          auth: true,
          scope: 'viewStoragePerformance'
        },
        name: 'StoragePerformance',
        component: () => import('@/views/storage/Performance')
      },
      {
        path: '/articles',
        meta: {
          title: 'order.items',
          group: 'apps',
          icon: 'mdi-file-tree',
          auth: true,
          scope: 'viewArticles'
        },
        name: 'Articles',
        component: () => import('@/views/shop/Articles')
      },
      {
        path: '/profit-profiles',
        meta: {
          title: 'different.profitProfiles',
          group: 'apps',
          icon: 'mdi-trending-up',
          auth: true,
          scope: 'viewProfitProfiles'
        },
        name: 'profitProfiles',
        component: () => import('@/views/shop/ProfitProfiles')
      },
      {
        path: '/profit-templates',
        meta: {
          title: 'different.profitTemplates',
          group: 'apps',
          icon: 'mdi-file-document',
          auth: true,
          scope: 'viewProfitProfiles'
        },
        name: 'profitTemplates',
        component: () => import('@/views/shop/ProfitTemplates')
      },
      {
        path: '/sets',
        meta: {
          title: 'menu.sets',
          group: 'apps',
          icon: 'mdi-cart',
          auth: true,
          scope: 'viewSets'
        },
        name: 'Sets',
        component: () => import('@/views/shop/Sets')
      },
      {
        path: '/search',
        meta: {
          title: 'toolbar.search',
          group: 'apps',
          icon: 'mdi-magnify',
          auth: true,
          scope: 'viewSearch'
        },
        name: 'Search',
        component: () => import('@/views/shop/Search')
      },
      {
        path: '/order-tracking',
        meta: {
          title: 'different.ordersTracking',
          group: 'apps',
          icon: 'mdi-google-maps',
          auth: true,
          scope: 'viewOrderTracking'
        },
        name: 'orderTracking',
        component: () => import('@/views/shop/OrderTracking')
      },
      {
        path: '/access',
        component: RouteWrapper,
        meta: {
          title: 'access.access',
          icon: 'mdi-monitor-lock',
          group: 'access',
          auth: true,
          scope: 'manage_permissions'
        },
        children: [
          {
            path: '/access/permissions',
            name: 'Permissions',
            meta: {
              title: 'different.permissions',
              auth: true,
              scope: 'manage_permissions'
            },
            component: () => import('@/views/access/Permissions')
          }
        ]
      },
      {
        path: '/users',
        meta: {
          title: 'user.users',
          group: 'apps',
          icon: 'mdi-account-multiple',
          auth: true,
          scope: 'edit_user'
        },
        name: 'Users',
        component: () => import('@/views/user/Users')
      },
      {
        path: '/projects',
        meta: {
          title: 'different.projects',
          group: 'apps',
          icon: 'mdi-briefcase',
          auth: true,
          scope: 'manage_projects'
        },
        name: 'Projects',
        component: () => import('@/views/project/Projects')
      },
      {
        path: '/service',
        meta: {
          title: 'different.service',
          group: 'apps',
          icon: 'mdi-wrench',
          auth: true,
          scope: 'service_app'
        },
        name: 'Service',
        component: () => import('@/views/Service')
      },
      {
        path: '/403',
        name: 'Forbidden',
        meta: {
          title: 'access.denied',
          hiddenInMenu: true,
          auth: false
        },
        component: () => import('@/views/error/Deny')
      },
      {
        path: '/profile',
        name: 'Profile',
        meta: {
          title: 'user.profile',
          hiddenInMenu: true,
          auth: true
        },
        component: () => import('@/views/user/Profile')
      }
    ]
  }
]
