import ru from 'vuetify/es5/locale/ru'

export default {
  user: {
    name: 'Имя',
    surname: 'Фамилия',
    employer: 'Сотрудник',
    employers: 'Сотрудники',
    selectOneEmployer: 'Выбрать сотрудника',
    user: 'Пользователь',
    users: 'Пользователи',
    profile: 'Профиль',
    profiles: 'Профили',
    currentPassword: 'Текущий пароль',
    newPassword: 'Новый пароль',
    confirmNewPassword: 'Подтвердите новый пароль',
    reporter: 'Репортер',
    selectReporter: 'Выберите репортера',
    assigned: 'Ответсвенный',
    assignTo: 'Назначить на',
    selectAssigned: 'Выберите ответственных пользователей',
    customer: 'Клиент',
    customers: 'Клиенты',
    customerGroup: 'Группа клиентов'
  },
  login: {
    email: 'Email',
    password: 'Пароль',
    submit: 'Войти',
    login: 'Логин',
    error: 'Email или пароль указан неверно',
    key: 'Ключ'
  },
  register: {
    register: 'Зарегистрироваться',
    confirm: 'Подтвердите пароль'
  },
  toolbar: {
    search: 'Поиск',
    profile: 'Профиль',
    settings: 'Настройки',
    logout: 'Выйти'
  },
  menu: {
    dashboard: 'Информационная панель',
    dealers: 'Поставщики',
    exchange: 'Обмен',
    storagePerformance: 'Производительность склада',
    sets: 'Сеты'
  },
  storage: {
    create: 'Создать новую запись'
  },
  manufacturer: {
    one: 'Производитель',
    many: 'Производители',
    selectOne: 'Выберите производителя',
    selectMany: 'Выберите производителей'
  },
  buttons: {
    ok: 'Ok',
    cancel: 'Отмена',
    close: 'Закрывать',
    create: 'Создать',
    save: 'Сохранить',
    action: 'Действие',
    delete: 'Удалить',
    refresh: 'Обновить',
    edit: 'Редактировать',
    new: 'Новый',
    download: 'Скачать',
    synchronize: 'Синхронизировать',
    history: 'История',
    clear: 'Очистить',
    view: 'Промотреть',
    all: 'Выбрать все',
    addTemplates: 'Добавить шаблоны',
    hide: 'Скрыть'
  },
  dates: {
    period: 'Период',
    selectPeriod: 'Выберите период',
    year: 'Год',
    month: 'Месяц',
    hours: 'Часы',
    from: 'От',
    to: 'До',
    date: 'Дата',
    dates: 'Даты',
    createdAt: 'Дата создания',
    updatedAt: 'Дата обновления',
    deletedAt: 'Дата удаления',
    orderStatus: 'Дата статуса заказа',
    statusDate: 'Дата статуса',
    days: 'Дни'
  },
  validation: {
    required: 'Это поле обязательно к заполнению',
    email: 'Электронная почта должна быть действительной',
    min8: 'Минимум 8 символов',
    min1: 'Минимальное значение 1',
    max191: 'Максимум 191 символ',
    max20: 'Максимум 20 символов',
    max255: 'Максимум 255 символов',
    confirmed: 'Пароли не соответствуют',
    maxSize10Mb: 'Размер файла не должен превышать 10 МБ.',
    maxNumber: "Должно быть меньше или равно ",
    minNumber: "Должно быть больше или равно ",
    incorrect: "Неверное значение"
  },
  different: {
    items: 'Позиции',
    title: 'Название',
    description: 'Описание',
    workingHours: 'Рабочие часы',
    setWorkingHours: 'Установить рабочее время',
    timeForItem: 'Время для позиции',
    deleteItemConfirm: 'Вы уверены, что хотите удалить выбранные элементы?',
    defaultConfirm: 'Вы уверены, что хотите выполнить это действие?',
    indicate: 'Indicates required field',
    editItem: 'Редактировать',
    newItem: 'Новый элемент',
    selectAvatar: 'Выберите аватар',
    photo: 'Фото',
    file: 'Файл',
    files: 'Файлы',
    deletePhoto: 'Удалить фото',
    permissions: 'Разрешения',
    permission: 'Разрешение',
    export: 'Экспорт',
    type: 'Тип',
    source: 'Источник',
    log: 'Лог',
    clientNumber: 'Номер клиента',
    currency: 'Валюта',
    rateToEuro: 'Отношение к EUR',
    default: 'Умолчание',
    synchronized: 'Синхронизировано',
    original: 'Оригинал',
    modified: 'Изменен',
    datev: 'Datev',
    paypal: 'PayPal',
    amazon: 'Amazon',
    ebay: 'Ebay',
    filter: 'Фильтр',
    remind: 'Напомнить',
    summary: 'Краткое изложение',
    shop: 'Магазин',
    field: 'Поле',
    change: 'Изменить',
    turnover: 'Оборот',
    statistics: 'Статистика',
    platforms: 'Платформы',
    platform: 'Платформа',
    result: 'Результат',
    stockValuation: 'Оценка запасов',
    handle: 'Управлять',
    other: 'Другой',
    comment: 'Комментарий',
    comments: 'Комментарии',
    trackingSearch: 'Поиск по коду отслеживания',
    projects: 'Проекты',
    project: 'Проект',
    selectProject: 'Выберите проект',
    logo: 'Логотип',
    service: 'Обслуживание',
    clearCache: 'Очистить кэш',
    back: 'Назад',
    increase: 'Увеличить',
    decrease: 'Уменьшить',
    constant: 'Константа',
    profitProfiles: 'Профили прибыли',
    profitTemplates: 'Шаблоны прибыли',
    ordersTracking: 'Отслеживание заказов',
    rules: 'Правила',
    value: 'Значение',
    sortOrder: 'Порядок сортировки',
  },
  ticket: {
    one: 'Тикет',
    many: 'Тикеты',
    closedPercent: 'закрытых тикетов',
    view: 'Просмотреть тикет',
    edit: 'Редактировать тикет',
    create: 'Создать тикет',
    history: 'История тикета',
    summary: 'Сводная информация о тикете'
  },
  order: {
    order: 'Заказ',
    orderMany: 'Заказы',
    number: 'Номер заказа',
    info: 'Информация о заказе',
    total: 'Итого',
    billing: 'Платежный адрес',
    shipping: 'Адрес доставки',
    paymentType: 'Способ оплаты',
    shippingType: 'Способ доставки',
    status: 'Статус заказа',
    paymentStatus: 'Статус оплаты',
    positions: 'Позиции',
    items: 'Артикли',
    itemNumber: 'Номер артикля',
    itemName: 'Название',
    quantity: 'Количество',
    price: 'Цена',
    totalPrice: 'Итоговая цена',
    purchasePrice: 'Закупочная цена',
    profitPrice: 'Цена прибыли',
    warehousePrice: 'Складская цена',
    profit: 'Выгода',
    afterSalePrice: 'Цена после продажи',
    dealerPlus: 'Дилер +',
    dealerMinus: 'Дилер -',
    dealerInvoiced: 'Выставленный счет диллеру',
    dealerOpen: 'Диллер(открытый)',
    dealerReserved: 'Диллер(резерв)',
    endCustomers: 'Конечные клиенты',
    inStock: 'В наличии',
    sum: 'Сумма',
    sample: 'Образец',
    taxRate: 'Ставка налога',
    net: 'Нетто',
    gross: 'Брутто',
    tracking: 'Код отслеживания'
  },
  call: {
    many: 'Звонки',
    next: 'Следующий звонок',
    success: 'Успех',
    wait: "Не дозвон",
    fail: 'Отказ'
  },
  errors: {
    error: 'Произошла ошибка'
  },
  messages: {
    saved: 'Успешно сохранено',
    sync: 'Синхронизировано успешно',
    deleted: 'Успешно удалено',
    wait: 'Подождите пожалуйста',
    cacheCleared: 'Кеш успешно очищен'
  },
  access: {
    access: 'Доступ',
    denied: 'В доступе отказано'
  },
  note: {
    one: 'Заметка',
    many: 'Заметки',
    add: 'Добавить заметку'
  },
  status: {
    status: 'Статус',
    selectStatus: 'Выберите статус',
    change: 'Изменить статус на',
    open: 'Открытый',
    closed: 'Закрытый',
    active: 'Активный',
    inactive: 'Неактивный',
    loaded: 'Закружен',
    saved: 'Сохранен',
    1: 'Открытый',
    5: 'Закрытый'
  },
  category: {
    selectCategory: 'Выберите категорию',
    selectOneCategory: 'Выберите только одну категорию',
    category: 'Категория',
    1: 'Возврат',
    2: 'Обмен',
    3: 'Дослать',
    4: 'Возврат ДС',
    5: 'Проблемы с доставкой',
    6: 'Другое',
    7: 'Производственный брак ',
  },
  sets: {
    searchSets: 'Искать артикли для сетов',
    searchItems: 'Поиск по номеру артикля'
  },

  ...ru
}
