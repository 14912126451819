import de from 'vuetify/es5/locale/de'

export default {
  user: {
    name: 'Name',
    surname: 'Nachname',
    employer: 'Angestellter',
    employers: 'Mitarbeiter',
    selectOneEmployer: 'Arbeitgeber auswählen',
    user: 'Nutzer',
    users: 'Benutzer',
    profile: 'Profil',
    profiles: 'Profile',
    currentPassword: 'Jetziges passwort',
    newPassword: 'Neues passwort',
    confirmNewPassword: 'Bestätige neues passwort',
    reporter: 'Reporter',
    selectReporter: 'Reporter auswählen',
    assigned: 'Zugewiesen',
    assignTo: 'Zuweisen',
    selectAssigned: 'Wählen Sie zugewiesene Benutzer aus',
    customer: 'Kunde',
    customers: 'Kunden',
    customerGroup: 'Kundengruppe'
  },
  login: {
    email: 'Email',
    password: 'Passwort',
    submit: 'Anmeldung',
    login: 'Anmeldung',
    error: 'Email oder Passwort ist falsch',
    key: 'Key'
  },
  register: {
    register: 'Registrieren',
    confirm: 'Kennwort bestätigen'
  },
  toolbar: {
    search: 'Suche',
    profile: 'Profil',
    settings: 'die Einstellungen',
    logout: 'Ausloggen'
  },
  menu: {
    dashboard: 'Instrumententafel',
    dealers: 'Händler',
    exchange: 'Austausch',
    storagePerformance: 'Speicherleistung',
    sets: 'Sätze'
  },
  storage: {
    create: 'Erstellen Sie Speicherleistung'
  },
  manufacturer: {
    one: 'Hersteller',
    many: 'Hersteller',
    selectOne: 'Hersteller auswählen',
    selectMany: 'Hersteller auswählen'
  },
  buttons: {
    ok: 'Ok',
    cancel: 'Stornieren',
    close: 'Schließen',
    create: 'Erstellen',
    save: 'Speichern',
    action: 'Aktion',
    delete: 'Löschen',
    refresh: 'Aktualisierung',
    edit: 'Bearbeiten',
    new: 'Neu',
    download: 'Herunterladen',
    synchronize: 'Synchronisieren',
    history: 'Geschichte',
    clear: 'Klar',
    view: 'Aussicht',
    all: 'Wählen Sie Alle',
    addTemplates: 'Vorlagen hinzufügen',
    hide: 'Ausblenden'
  },
  dates: {
    period: 'Zeitraum',
    selectPeriod: 'Zeitraum auswählen',
    year: 'Jahr',
    month: 'Monat',
    hours: 'Std',
    from: 'Von',
    to: 'Zu',
    date: 'Datum',
    dates: 'Termine',
    createdAt: 'Hergestellt in',
    updatedAt: 'Aktualisiert am',
    deletedAt: 'Gelöscht bei',
    orderStatus: 'Auftragsstatusdatum',
    statusDate: 'Status Datum',
    days: 'Tage'
  },
  validation: {
    required: 'Dieses Feld ist erforderlich',
    email: 'Email muss gültig sein',
    min8: 'Mindestens 8 Zeichen',
    min1: 'Mindestwert 1',
    max191: 'Maximal 191 Zeichen',
    max20: 'Maximal 20 Zeichen',
    max255: 'Maximal 255 Zeichen',
    confirmed: 'Passwörter stimmen nicht überein',
    maxSize10Mb: 'Die Dateigröße sollte weniger als 10 MB betragen',
    maxNumber: "Muss größer oder gleich sein ",
    minNumber: "Muss kleiner oder gleich sein ",
    incorrect: "Falscher Wert"
  },
  different: {
    items: 'Artikel',
    title: 'Titel',
    description: 'Beschreibung',
    workingHours: 'Working hours',
    setWorkingHours: 'Arbeitszeit',
    timeForItem: 'Zeit für Artikel',
    deleteItemConfirm: 'Möchten Sie die ausgewählten Elemente wirklich löschen?',
    defaultConfirm: 'Sind Sie sicher, dass Sie diese Aktion ausführen möchten?',
    indicate: 'Zeigt das erforderliche Feld an',
    editItem: 'Element bearbeiten',
    newItem: 'Neuer Gegenstand',
    selectAvatar: 'Wähle einen Avatar',
    photo: 'Foto',
    file: 'Datei',
    files: 'Dateien',
    deletePhoto: 'Foto löschen',
    permissions: 'Berechtigungen',
    permission: 'Genehmigung',
    export: 'Export',
    type: 'Art',
    source: 'Quelle',
    log: 'log',
    clientNumber: 'Kundennummer',
    currency: 'Währung',
    rateToEuro: 'Preis in EUR',
    default: 'Standard',
    synchronized: 'Synchronisiert',
    original: 'Original',
    modified: 'Geändert',
    datev: 'Datev',
    paypal: 'PayPal',
    amazon: 'Amazon',
    ebay: 'Ebay',
    filter: 'Filter',
    remind: 'Erinnern',
    summary: 'Zusammenfassung',
    shop: 'Geschäft',
    field: 'Feld',
    change: 'Veränderung',
    turnover: 'Umsatz',
    statistics: 'Statistiken',
    platforms: 'Platforms',
    platform: 'Platform',
    result: 'Ergebnis',
    stockValuation: 'Aktienbewertung',
    handle: 'Griff',
    other: 'Andere',
    comment: 'Kommentar',
    comments: 'Bemerkungen',
    trackingSearch: 'Suche nach Tracking-Code',
    projects: 'Projects',
    project: 'Project',
    selectProject: 'Projekt auswählen',
    logo: 'Logo',
    service: 'Service',
    clearCache: 'Cache leeren',
    back: 'Zurück',
    increase: 'Zunahme',
    decrease: 'Verkleinern',
    constant: 'Konstante',
    profitProfiles: 'Gewinnprofile',
    profitTemplates: 'Gewinnvorlagen',
    ordersTracking: 'Sendungsverfolgung',
    rules: 'Regeln',
    value: 'Wert',
    sortOrder: 'Sortierreihenfolge',
  },
  ticket: {
    one: 'Fahrkarte',
    many: 'Tickets',
    closedPercent: 'Tickets geschlossen',
    view: 'Ticket anzeigen',
    edit: 'Ticket bearbeiten',
    create: 'Ticket erstellen',
    history: 'Ticketverlauf',
    summary: 'Zusammenfassende Informationen zum Ticket'
  },
  order: {
    order: 'Bestellung',
    orderMany: 'Aufträge',
    number: 'Bestellnummer',
    info: 'Bestellinformationen',
    total: 'Gesamtsumme',
    billing: 'Abrechnung',
    shipping: 'Versand',
    paymentType: 'Zahlungsart',
    shippingType: 'Versandart',
    status: 'Bestellstatus',
    paymentStatus: 'Zahlungsstatus',
    positions: 'Positionen',
    items: 'Artikel',
    itemNumber: 'Artikelnummer',
    itemName: 'Artikelname',
    quantity: 'Menge',
    price: 'Preis',
    totalPrice: 'Gesamtpreis',
    purchasePrice: 'Kaufpreis',
    profitPrice: 'Gewinnpreis',
    warehousePrice: 'Lagerpreis',
    profit: 'Profitieren',
    afterSalePrice: 'nach Verkaufspreis',
    dealerPlus: 'Händler +',
    dealerMinus: 'Händler -',
    dealerInvoiced: 'Rechnungshändler',
    dealerOpen: 'Händler geöffnet',
    dealerReserved: 'Reservierter Händler',
    endCustomers: 'Endkunden',
    inStock: 'auf Lager',
    sum: 'Summe',
    sample: 'Muster',
    taxRate: 'Steuersatz',
    net: 'Netz',
    gross: 'Brutto',
    tracking: 'Verfolgungscode'
  },
  call: {
    many: 'Anrufe',
    next: 'Nächster Anruf',
    success: 'Erfolg',
    wait: 'Bin nicht durchgekommen',
    fail: 'Verzicht'
  },
  errors: {
    error: 'Ein Fehler ist aufgetreten'
  },
  messages: {
    saved: 'Erfolgreich gespeichert',
    sync: 'Erfolgreich synchronisiert',
    deleted: 'Erfolgreich gelöscht',
    wait: 'Warten Sie mal',
    cacheCleared: 'Cache erfolgreich geleert'
  },
  access: {
    access: 'Zugriff',
    denied: 'Zugriff abgelehnt'
  },
  note: {
    one: 'Hinweis',
    many: 'Anmerkungen',
    add: 'Notiz hinzufügen'
  },
  status: {
    status: 'Status',
    selectStatus: 'Status auswählen',
    change: 'Ändern Sie den Status in',
    open: 'Öffnen',
    closed: 'Geschlossen',
    active: 'Aktiv',
    inactive: 'Inaktiv',
    loaded: 'Geladen',
    saved: 'Gerettet',
    1: 'Öffnen',
    5: 'Geschlossen'
  },
  category: {
    selectCategory: 'Kategorie wählen',
    selectOneCategory: 'Wählen Sie nur eine Kategorie aus',
    category: 'Kategorie',
    1: 'Rückkehr',
    2: 'Austausch',
    3: 'Senden',
    4: 'Rückgabe von DS',
    5: 'Probleme mit der Lieferung',
    6: 'Andere',
    7: 'Produktionsfehler'
  },
  sets: {
    searchSets: 'Artikel für Sets suchen',
    searchItems: 'Suche nach Artikelnummer'
  },

  ...de
}
