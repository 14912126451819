import colors from 'vuetify/es5/util/colors'

const state = {
  mode: 'light',
  themeColor: 'indigo',
  apiUrl: '/',
  apiTokenUrl: '/api/login',
  apiClientId: 2,
  apiClientSecret: 'vpKFWEfvFUVS8jl3a9s6uERcLqSAyUxkgZdEkUts',
  ticketCategories: null,
  ticketPriorities: null,
  ticketStatuses: null,
  usersList: null,
  users: [],
  projects: [],
  platforms: [],
  currentProject: null,
  authUser: null,
  appLocales: [
    {
      locale: 'en',
      label: 'English'
    },
    {
      locale: 'de',
      label: 'Deutsche'
    },
    {
      locale: 'ru',
      label: 'Русский'
    }
  ]
}

// getters
const getters = {
  getThemeColor: (state) => {
    return colors[state.themeColor].base
  },
  getApiUrl: (state) => {
    return state.apiUrl
  },
  getApiTokenUrl: (state) => {
    return state.apiTokenUrl
  },
  getApiClientId: (state) => {
    return state.apiClientId
  },
  getApiClientSecret: (state) => {
    return state.apiClientSecret
  },
  getTicketCategories: (state) => {
    return state.ticketCategories
  },
  getTicketStatuses: (state) => {
    return state.ticketStatuses
  },
  getUsersList: (state) => {
    return state.usersList
  },
  getUsers: (state) => {
    return state.users
  },
  getProjects: (state) => {
    return state.projects
  },
  getPlatforms: (state) => {
    return state.platforms
  },
  getCurrentProject: (state) => {
    return state.currentProject
  },
  getAuthUser: (state) => {
    return state.authUser
  },
  getLocales: (state) => {
    return state.appLocales
  },
  getScopes: (state) => {
    if (!localStorage.getItem('access_token')) return null
    else {
      let token = localStorage.getItem('access_token')
      let base64Url = token.split('.')[1]
      let base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
      let jsonPayload = decodeURIComponent(
        atob(base64)
          .split('')
          .map(function(c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
          })
          .join('')
      )

      let res = JSON.parse(jsonPayload)

      return res.scopes
    }
  }
}

// actions
const actions = {}

// mutations
const mutations = {
  setThemeColor(state, payload) {
    state.themeColor = payload
  },
  setTicketCategories(state, payload) {
    state.ticketCategories = payload
  },
  setTicketStatuses(state, payload) {
    state.ticketStatuses = payload
  },
  setUsersList(state, payload) {
    state.usersList = payload
  },
  setUsers(state, payload) {
    state.users = payload
  },
  setProjects(state, payload) {
    state.projects = payload
  },
  setPlatforms(state, payload) {
    state.platforms = payload
  },
  setCurrentProject(state, payload) {
    state.currentProject = payload
  },
  setAuthUser(state, payload) {
    state.authUser = payload
  }
}

export default {
  namespace: true,
  state,
  getters,
  actions,
  mutations
}
