<template>
  <v-app-bar color="primary" dark app>
    <v-app-bar-nav-icon @click="handleDrawerToggle" />
    <v-spacer />
    <v-toolbar-items>
      <!--      <v-menu
        offset-y
        origin="center center"
        class="elelvation-1"
        transition="scale-transition"
      >
        <template v-slot:activator="{ on }">
          <v-btn icon text slot="activator" v-on="on">
            <v-badge color="red" overlap>
              <span slot="badge">3</span>
              <v-icon medium>notifications</v-icon>
            </v-badge>
          </v-btn>
        </template>
        <notification-list></notification-list>
      </v-menu>-->
      <v-menu
        offset-y
        origin="center center"
        class="elelvation-1"
        transition="scale-transition"
      >
        <template v-slot:activator="{ on }">
          <v-btn text slot="activator" v-on="on">
            <v-icon medium>mdi-google-translate</v-icon>
            <span class="ml-2"> {{ localeText }} </span>
          </v-btn>
        </template>
        <v-list>
          <v-list-item-group v-model="$vuetify.lang.current">
            <v-list-item
              @click="handleChangeLocale(item)"
              v-for="item in compLocales"
              :key="item.locale"
              :value="item.locale"
              :disabled="$vuetify.lang.current === item.locale"
            >
              <v-list-item-title v-text="item.label" />
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-menu>
      <v-menu offset-y origin="center center" transition="scale-transition">
        <template v-slot:activator="{ on }">
          <v-btn icon large text slot="activator" v-on="on">
            <v-avatar size="36">
              <img :src="compAvatar" alt="Photo" />
            </v-avatar>
          </v-btn>
        </template>
        <v-list class="pa-0">
          <v-list-item
            v-for="(item, index) in profileMenus"
            :to="!item.href ? { name: item.name } : null"
            :href="item.href"
            @click="item.click"
            ripple="ripple"
            :disabled="item.disabled"
            :target="item.target"
            rel="noopener"
            :key="index"
          >
            <v-list-item-action v-if="item.icon">
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-toolbar-items>
    <!--<v-toolbar tag="div" dense slot="extension" color="white" light>
      <v-icon>mdi-home</v-icon>
      <v-breadcrumbs :items="breadcrumbs" class="pa-3" />
      <v-spacer></v-spacer>
      <v-btn icon small color="black">
        <v-icon v-text="'mdi-arrow-left'" @click="handleGoBack" />
      </v-btn>
    </v-toolbar>-->
  </v-app-bar>
</template>
<script>
/*import NotificationList from '@/components/widgets/list/NotificationList'*/
import Util from '@/util'

export default {
  name: 'AppToolbar',
  components: {
    /*NotificationList*/
  },
  data() {
    return {}
  },
  computed: {
    toolbarColor() {
      return this.$vuetify.options.extra.mainNav
    },
    breadcrumbs() {
      const { matched } = this.$route
      return matched.map((route, index) => {
        const to =
          index === matched.length - 1
            ? this.$route.path
            : route.path || route.redirect
        return {
          text: route.meta.title,
          to: to,
          exact: true,
          disabled: false
        }
      })
    },
    compAuthHeader() {
      return {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('access_token')
        }
      }
    },
    compAvatar() {
      return this.$store.getters.getAuthUser && this.$store.getters.getAuthUser.avatar
        ? this.$store.getters.getAuthUser.avatar
        : 'static/img/av.png'
    },
    compLocales() {
      return this.$store.getters.getLocales
    },
    localeText() {
      const find = this.compLocales.find(
        (item) => item.locale === this.$vuetify.lang.current
      )
      return find.label
    },
    profileMenus() {
      return [
        {
          icon: 'mdi-account',
          href: '#/profile',
          title: this.$vuetify.lang.t('$vuetify.user.profile'),
          click: this.handleProfile
        },
        /*{
          icon: 'settings',
          href: '#',
          title: 'Settings',
          click: this.handleSetting
        },*/
        {
          icon: 'mdi-power',
          href: '#',
          title: this.$vuetify.lang.t('$vuetify.toolbar.logout'),
          click: this.handleLogout
        }
      ]
    }
  },
  methods: {
    handleDrawerToggle() {
      this.$emit('side-icon-click')
    },
    handleFullScreen() {
      Util.toggleFullScreen()
    },
    handleLogout() {
      this.$axios
        .post(
          this.$store.getters.getApiUrl + 'api/logout',
          {},
          this.compAuthHeader
        )
        .then((response) => {})
        .catch((error) => {
          console.log(error)
        })
      localStorage.removeItem('access_token')
      this.$router.push('/auth/login')
    },
    handleSetting() {},
    handleProfile() {
      //this.$router.push('/profile')
    },
    handleGoBack() {
      this.$router.go(-1)
    },
    handleChangeLocale(value) {
      localStorage.setItem('app_locale', value.locale)
      this.$vuetify.lang.current = value.locale
    }
  },
  created() {}
}
</script>

<style lang="sass" scoped></style>
