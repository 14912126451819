<template>
  <v-app :dark="true">
    <router-view></router-view>
    <!-- setting drawer -->
    <v-navigation-drawer
      class="setting-drawer"
      temporary
      right
      v-model="rightDrawer"
      hide-overlay
      fixed
    >
      <theme-settings></theme-settings>
    </v-navigation-drawer>
  </v-app>
</template>

<script>
import ThemeSettings from '@/components/ThemeSettings'

export default {
  components: {
    ThemeSettings
  },
  data() {
    return {
      rightDrawer: false
    }
  },
  computed: {
    compAuthHeader() {
      return {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('access_token')
        }
      }
    }
  },
  mounted() {
  },
  created() {
    // add app events
    this.init()
    //setInterval(this.checkCurrentProjectChange, 5000);

    /*this.$root.$on('PROJECT_CHANGED', () => {
      console.log('CHANGED')
    })*/
  },
  methods: {
    openThemeSettings() {
      this.$vuetify.goTo(0)
      this.rightDrawer = !this.rightDrawer
    },
    init() {
      if (localStorage.getItem('access_token')) {
        this.$axios
          .post(this.$store.getters.getApiUrl + 'api/init', {}, this.compAuthHeader)
          .then((response) => {
            if (typeof response.data.ticket_categories !== 'undefined')
              this.$store.commit('setTicketCategories', response.data.ticket_categories)
            if (typeof response.data.ticket_statuses !== 'undefined')
              this.$store.commit('setTicketStatuses', response.data.ticket_statuses)
            if (typeof response.data.users_list !== 'undefined')
              this.$store.commit('setUsersList', response.data.users_list)
            if (typeof response.data.users !== 'undefined')
              this.$store.commit('setUsers', response.data.users)
            if (typeof response.data.auth_user !== 'undefined')
              this.$store.commit('setAuthUser', response.data.auth_user)
            if (typeof response.data.platforms !== 'undefined')
              this.$store.commit('setPlatforms', response.data.platforms)
            if (typeof response.data.projects !== 'undefined') {
              this.$store.commit('setProjects', response.data.projects)

              if (localStorage.getItem('current_project_id')) {
                let projects = this.$store.getters.getProjects
                let t = this
                let project = null

                projects.map(function (item) {
                  if (localStorage.getItem('current_project_id') * 1 === item.id) {
                    t.$store.commit('setCurrentProject', item)
                    project = item
                  }
                })

                if (!project) {
                  this.$store.commit('setCurrentProject', response.data.projects[0])
                  project = response.data.projects[0]
                }
                localStorage.setItem('current_project_id', project.id)
              } else this.$store.commit('setCurrentProject', response.data.projects[0])
            }
          })
          .catch((error) => {
            console.log(error)
          })
      }
    },
    /*checkCurrentProjectChange() {
      if (this.$store.getters.getCurrentProject && localStorage.getItem('current_project_id')
        && this.$store.getters.getCurrentProject.id !== localStorage.getItem('current_project_id') * 1) {
        let projects = this.$store.getters.getProjects
        let t = this

        projects.map(function (item) {
          if (localStorage.getItem('current_project_id') * 1 === item.id) {
            t.$store.commit('setCurrentProject', item)
            t.$root.$emit('PROJECT_CHANGED')
          }
        })
      }
    }*/
  }
}
</script>

<style lang="sass" scoped>
.setting-fab
  top: 50% !important
  right: 0
  border-radius: 0
</style>
