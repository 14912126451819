import Vue from 'vue'
import Router from 'vue-router'
import { publicRoute, protectedRoute } from './config'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'

const routes = publicRoute.concat(protectedRoute)

Vue.use(Router)
const router = new Router({
  mode: 'hash',
  linkActiveClass: 'active',
  routes: routes
})
// router guards
router.beforeEach((to, from, next) => {
  NProgress.start()
  // remove expired token
  if (localStorage.getItem('access_token') && localStorage.getItem('expired')) {
    if (new Date().getTime() > localStorage.getItem('expired') * 1) {
      localStorage.removeItem('access_token')
      localStorage.removeItem('refresh_token')
      localStorage.removeItem('expired')
    }
  }

  //auth route is authenticated
  if (to.meta.auth && !localStorage.getItem('access_token')) {
    NProgress.done()
    next('/auth/login')
  } else next()
})

router.afterEach(() => {
  NProgress.done()
})

export default router
