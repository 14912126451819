import en from 'vuetify/es5/locale/en'

export default {
  user: {
    name: 'Name',
    surname: 'Surname',
    employer: 'Employee',
    employers: 'Employees',
    selectOneEmployer: 'Select employee',
    user: 'User',
    users: 'Users',
    profile: 'Profile',
    profiles: 'Profiles',
    currentPassword: 'Current password',
    newPassword: 'New password',
    confirmNewPassword: 'Confirm new password',
    reporter: 'Reporter',
    selectReporter: 'Select reporter',
    assigned: 'Assigned',
    assignTo: 'Assign to',
    selectAssigned: 'Select assigned users',
    customer: 'Customer',
    customers: 'Customers',
    customerGroup: 'Customer group'
  },
  login: {
    email: 'Email',
    password: 'Password',
    submit: 'Login',
    login: 'Login',
    error: 'Email or password is incorrect',
    key: 'Key'
  },
  register: {
    register: 'Register',
    confirm: 'Confirm password'
  },
  toolbar: {
    search: 'Search',
    profile: 'Profile',
    settings: 'Settings',
    logout: 'Logout'
  },
  menu: {
    dashboard: 'Dashboard',
    dealers: 'Dealers',
    exchange: 'Exchange',
    storagePerformance: 'Storage performance',
    sets: 'Sets'
  },
  storage: {
    create: 'Create storage performance'
  },
  manufacturer: {
    one: 'Manufacturer',
    many: 'Manufacturers',
    selectOne: 'Select manufacturer',
    selectMany: 'Select manufacturers'
  },
  buttons: {
    ok: 'Ok',
    cancel: 'Cancel',
    close: 'Close',
    create: 'Create',
    save: 'Save',
    action: 'Action',
    delete: 'Delete',
    refresh: 'Refresh',
    edit: 'Edit',
    new: 'New',
    download: 'Download',
    synchronize: 'Synchronize',
    history: 'History',
    clear: 'Clear',
    view: 'View',
    all: 'Select all',
    addTemplates: 'Add templates',
    hide: 'Hide'
  },
  dates: {
    period: 'Period',
    selectPeriod: 'Select period',
    year: 'Year',
    month: 'Month',
    hours: 'Hours',
    from: 'From',
    to: 'To',
    date: 'Date',
    dates: 'Dates',
    createdAt: 'Created at',
    updatedAt: 'Updated at',
    deletedAt: 'Deleted at',
    orderStatus: 'Order status date',
    statusDate: 'Status date',
    days: 'Days'
  },
  validation: {
    required: 'This field is required',
    email: 'Email must be valid',
    min8: 'Minimum 8 characters',
    min1: 'Minimum value 1',
    max191: 'Maximum 191 characters',
    max20: 'Maximum 20 characters',
    max255: 'Maximum 255 characters',
    confirmed: 'Passwords do not match',
    maxSize10Mb: 'File size should be less than 10 MB.',
    maxNumber: "Must be less than or equal to ",
    minNumber: "Must be greater than or equal to ",
    incorrect: "Incorrect value"
  },
  different: {
    items: 'Items',
    title: 'Title',
    description: 'Description',
    workingHours: 'Working hours',
    setWorkingHours: 'Set working hours',
    timeForItem: 'Time for item',
    deleteItemConfirm: 'Are you sure you want to delete selected item(s)?',
    defaultConfirm: 'Are you sure you want to perform this action?',
    indicate: 'Indicates required field',
    editItem: 'Edit item',
    newItem: 'New item',
    selectAvatar: 'Pick an avatar',
    photo: 'Photo',
    file: 'File',
    files: 'Files',
    deletePhoto: 'Delete photo',
    permissions: 'Permissions',
    permission: 'Permission',
    export: 'Export',
    type: 'Type',
    source: 'Source',
    log: 'log',
    clientNumber: 'Client number',
    currency: 'Currency',
    rateToEuro: 'Rate to EUR',
    default: 'Default',
    synchronized: 'Synchronized',
    original: 'Original',
    modified: 'Modified',
    datev: 'Datev',
    paypal: 'PayPal',
    amazon: 'Amazon',
    ebay: 'Ebay',
    filter: 'Filter',
    remind: 'Remind',
    summary: 'Summary',
    shop: 'Shop',
    field: 'Field',
    change: 'Change',
    turnover: 'Turnover',
    statistics: 'Statistics',
    platforms: 'Platforms',
    platform: 'Platform',
    result: 'Result',
    stockValuation: 'Stock valuation',
    handle: 'Handle',
    other: 'Other',
    comment: 'Comment',
    comments: 'Comments',
    trackingSearch: 'Search by tracking code',
    projects: 'Projects',
    project: 'Project',
    selectProject: 'Select project',
    logo: 'Logo',
    service: 'Service',
    clearCache: 'Clear cache',
    back: 'Back',
    increase: 'Increase',
    decrease: 'Decrease',
    constant: 'Constant',
    profitProfiles: 'Profit profiles',
    profitTemplates: 'Profit templates',
    ordersTracking: 'Orders tracking',
    rules: 'Rules',
    value: 'Value',
    sortOrder: 'Sort order',
  },
  ticket: {
    one: 'Ticket',
    many: 'Tickets',
    closedPercent: 'tickets closed',
    view: 'View ticket',
    edit: 'Edit ticket',
    create: 'Create ticket',
    history: 'Ticket history',
    summary: 'Summary information about the ticket'
  },
  order: {
    order: 'Order',
    orderMany: 'Orders',
    number: 'Order number',
    info: 'Order information',
    total: 'Total amount',
    billing: 'Billing',
    shipping: 'Shipping',
    paymentType: 'Payment type',
    shippingType: 'Shipping type',
    status: 'Order status',
    paymentStatus: 'Payment status',
    positions: 'Positions',
    items: 'Items',
    itemNumber: 'Item number',
    itemName: 'Item name',
    quantity: 'Quantity',
    price: 'Price',
    totalPrice: 'Total price',
    purchasePrice: 'Purchase price',
    profitPrice: 'Profit price',
    warehousePrice: 'Warehouse price',
    profit: 'Profit',
    afterSalePrice: 'After sale price',
    dealerPlus: 'Dealer +',
    dealerMinus: 'Dealer -',
    dealerInvoiced: 'Invoiced dealer',
    dealerOpen: 'Opened dealer',
    dealerReserved: 'Reserved dealer',
    endCustomers: 'End customers',
    inStock: 'In stock',
    sum: 'Sum',
    sample: 'Sample',
    taxRate: 'Tax rate',
    net: 'Net',
    gross: 'Gross',
    tracking: 'Tracking code'
  },
  call: {
    many: 'Calls',
    next: 'Next call',
    success: 'Success',
    wait: "Didn't get through",
    fail: 'Renouncement'
  },
  errors: {
    error: 'An error has occurred'
  },
  messages: {
    saved: 'Saved successfully',
    sync: 'Synchronized successfully',
    deleted: 'Deleted successfully',
    wait: 'Please wait',
    cacheCleared: 'Cache cleared successfully'
  },
  access: {
    access: 'Access',
    denied: 'Access Denied'
  },
  note: {
    one: 'Note',
    many: 'Notes',
    add: 'Add note'
  },
  status: {
    status: 'Status',
    selectStatus: 'Select status',
    change: 'Change status to',
    open: 'Open',
    closed: 'Closed',
    active: 'Active',
    inactive: 'Inactive',
    loaded: 'Loaded',
    saved: 'Saved',
    1: 'Open',
    5: 'Closed'
  },
  category: {
    selectCategory: 'Select category',
    selectOneCategory: 'Select only one category',
    category: 'Category',
    1: 'Return',
    2: 'Exchange',
    3: 'Send',
    4: 'Return of DS',
    5: 'Problems with delivery',
    6: 'Other',
    7: 'Manufacturing defect',
  },
  sets: {
    searchSets: 'Search items for sets',
    searchItems: 'Search by article number'
  },

  ...en
}
